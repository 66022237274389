import React, { useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { PageProps } from 'gatsby'
import { LifeStyle } from '../component/page_component/LifeStyle'
import { LifeStlyePageSlider } from '../component/LifeStlyePageSlider'
import { makeStyles } from '@material-ui/core/styles'
import { LIFESTYLE } from '../utils/constants'
import { useIntl } from 'gatsby-plugin-intl'
import { SearchContext } from '../contexts/searchContext'

const useStyles = makeStyles((theme) => ({
  lifestyleSlider: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(10),
  },
}))

const LifestyleShowcase: React.FC<PageProps> = (props: any) => {
  const {
    location,
    pageContext: { data },
  } = props
  const intl = useIntl()
  const classes = useStyles()
  const { flushSearchState } = useContext(SearchContext)

  useEffect(() => {
    flushSearchState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const type = location?.pathname.split('/')[2]
  let slideData = {
    title: '',
    mainImg: '',
    mainImgAlt: '',
    leftImg: '',
    leftImgAlt: '',
    rightImg: '',
    rightImgAlt: '',
    details: '',
  }

  if (type && LIFESTYLE[type]) {
    slideData = {
      title: intl.formatMessage({ id: LIFESTYLE[type].titleTranslation }),
      details: intl.formatMessage({ id: 'lifestyle.subtitle' }),
      mainImg: LIFESTYLE[type].mainImg,
      mainImgAlt: LIFESTYLE[type].mainImg,
      leftImg: LIFESTYLE[type].leftImg,
      leftImgAlt: LIFESTYLE[type].leftImg,
      rightImg: LIFESTYLE[type].rightImg,
      rightImgAlt: LIFESTYLE[type].rightImg,
    }
  }

  return (
    <Layout location={location} showFlotingMenu={false} title={slideData.title + ' - Kretz'}>
      <Grid container justifyContent="center" id="lifestyleShowcase-top-dream-destination">
        <LifeStlyePageSlider
          mainImgAlt={slideData.mainImgAlt}
          rightImgAlt={slideData.rightImgAlt}
          leftImgAlt={slideData.leftImgAlt}
          leftImg={slideData.leftImg}
          rightImg={slideData.rightImg}
          mainImg={slideData.mainImg}
          details={slideData.details}
          title={slideData.title}
        />
      </Grid>
      <Grid container justifyContent="center" id="lifestyleShowcase-bottom-dream-destination">
        <PropertyList bgClr="#E0E5DE" data={data} location={location} />
      </Grid>
      <Grid container justifyContent="center" className={classes.lifestyleSlider}>
        <LifeStyle />
      </Grid>
    </Layout>
  )
}

export default LifestyleShowcase

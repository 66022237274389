import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import { ArrowIcon } from '../icons/ArrowIcon'
import { Image } from './Images'
import { useIntl } from 'gatsby-plugin-intl'

const ImgMainDiv = styled.div`
  padding-right: 0.5rem;
  font-family: 'Gotham Bold', serif;
  .browse-txt {
    position: absolute;
    display: none;
    bottom: 1.5rem;
    font-size: 1.3rem;
    margin-left: -6px;
    @keyframes fadeInBrowse {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-moz-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-webkit-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-o-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-ms-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @media (min-width: 960px) {
      animation: fadeInBrowse linear 0.5s;
      -webkit-animation: fadeInBrowse linear 0.5s;
      -moz-animation: fadeInBrowse linear 0.5s;
      -o-animation: fadeInBrowse linear 0.5s;
      -ms-animation: fadeInBrowse linear 0.5s;
    }
  }
  .img-txt {
    position: absolute;
    text-transform: uppercase;
    margin-top: -100px;
    font-size: 1.3rem;
    font-weight: initial;
    transition: margin-top ease 0.5s;
    letter-spacing: 0.05em;
    line-height: 1.1;
    width: 70%;
  }
  @media (max-width: 960px) and (min-width: 813px) {
    .img-txt {
      margin-top: -200px;
      font-size: 26px;
    }
    .browse-txt {
      display: block;
      font-size: 1.5rem;
    }
  }

  @media (max-width: 812px) {
    .img-txt {
      font-size: 14px;
    }
    .browse-txt {
      font-size: 1rem;
    }
  }

  &:hover {
    .browse-txt {
      display: block;
    }
    .img-txt {
      margin-top: -150px;
    }
  }
  .arrow: {
    font-size: 2rem;
  }
`

const Details = styled.div`
  fontfamily: 'Gotham Book', serif;
  font-weight: bold;
  color: #ffff;
  text-align: left;
  margin-left: 1.2rem;
`

const IconSpan = styled.span`
  margin-right: 0.5rem;
`

interface SliderImgLifestyleProps {
  src: string
  text?: string
  isBrowse?: boolean
  alt: string
  to?: string
  height?: string
}

export const SliderImgLifestyle: React.FC<SliderImgLifestyleProps> = (props) => {
  const { src, text, isBrowse = true, alt, to, height = 'inherit' } = props
  const intl = useIntl()

  return (
    <ImgMainDiv>
      <Image
        src={src}
        alt={alt}
        layout="CONSTRAINED"
        className="slide-img"
        style={{ height: height }}
      />
      <Details>
        {text && (
          <Box className="img-txt">
            {
              <>
                <span> {text.split(' ')[0]} </span> <br />{' '}
                <span> {text.split(' ').splice(1).join(' ')} </span>
              </>
            }
          </Box>
        )}
        {isBrowse && to && (
          <Box className="browse-txt">
            <a href={to} style={{ textDecoration: 'none', color: '#fff' }}>
              <IconSpan>{intl.formatMessage({ id: 'browse' })}</IconSpan>
              <ArrowIcon style={{ marginLeft: '10px' }} />
            </a>
          </Box>
        )}
      </Details>
    </ImgMainDiv>
  )
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { LifeStyleSlider } from './LifeStyleSlider'

import { useIntl } from 'gatsby-plugin-intl'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: '1em 2em 4em 2em',
    },
    /* fontFamily: `'Cormorant Garamond', serif`,
    padding: '6em 0',
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.between('md', 'md')]: {
      minHeight: '600px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      minHeight: '380px',
      padding: '0 0 4em 0',
    }, */
  },
  inner: {
    flexBasis: '80%',
    justifyContent: 'space-between',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: 'unset',
    },
  },
  heightFull: {
    height: '100%',
    marginTop: '-6rem',
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: '-3.8rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '-1.8rem',
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(130),
    //marginTop: '-5.8rem',
    marginTop: theme.spacing(4),
    textTransform: 'uppercase',
    textAlign: 'center',
    zIndex: 9,
    [theme.breakpoints.between('md', 'md')]: {
      //marginTop: theme.typography.pxToRem(-55),
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      //marginTop: '-1.6rem',
      fontSize: theme.typography.pxToRem(40),
    },
  },
}))

export const LifeStyle = () => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Box className={classes.root}>
      <Grid container className={classes.inner}>
        <Grid item xs={12} className={classes.title}>
          {intl.formatMessage({ id: 'lifeStyles' })}
        </Grid>
        <Grid item xs={12} className={classes.heightFull}>
          <LifeStyleSlider />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LifeStyle

import React from 'react'
import Carousel from 'react-multi-carousel'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { SliderImgLifestyle } from '../../SliderImgLifestyle'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { useMediaQuery } from '@material-ui/core'
import theme from '../../../Theme/theme'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    partialVisibilityGutter: 100,
  },
  desktop: {
    breakpoint: { max: 3000, min: 960 },
    items: 4,
    partialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 960, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const useStyles = makeStyles(() => ({
  img: {
    paddingRight: '2rem',
    [theme.breakpoints.between('md', 'md')]: {
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingRight: '1rem',
    },
  },
}))

export const LifeStyleSlider = () => {
  const classes = useStyles()
  const intl = useIntl()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid >
      <Carousel
        responsive={responsive}
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={5000}
        containerClass="container"
        focusOnSelect={false}
        infinite
        centerMode={matches}
        partialVisible={!matches}
        keyBoardControl
        minimumTouchDrag={10}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        //itemClass={`${classes.item}`}
      >
        <Box className={classes.img}>
          <SliderImgLifestyle
            src="Component43–1@2x.png"
            alt="Image Lifestyle domain-and-castel"
            text={intl.formatMessage({ id: 'lifestyle.domainAndCastel' })}
            to={`/${intl.locale}/domain-and-castel`}
          />
        </Box>
        <Box className={classes.img}>
          <SliderImgLifestyle
            src="Component42–1@2x.png"
            alt="Image Lifestyle dream-villa"
            text={intl.formatMessage({ id: 'lifestyle.dreamVilla' })}
            to={`/${intl.locale}/dream-villa`}
            //height="75vh"
          />
        </Box>
        <Box className={classes.img}>
          <SliderImgLifestyle
            src="Component44–1@2x.png"
            alt="Image Lifestyle family-chalets"
            text={intl.formatMessage({ id: 'lifestyle.familyChalets' })}
            to={`/${intl.locale}/family-chalets`}
            //height="75vh"
          />
        </Box>
        <Box className={classes.img}>
          <SliderImgLifestyle
            src="Component45–1@2x.png"
            alt="Image Lifestyle typical-space"
            text={intl.formatMessage({ id: 'lifestyle.typicalSpace' })}
            to={`/${intl.locale}/typical-space`}
            //height="75vh"
          />
        </Box>
        <Box className={classes.img}>
          <SliderImgLifestyle
            src="Component46–1@2x.png"
            alt="Image Lifestyle garden-terrace"
            text={intl.formatMessage({ id: 'lifestyle.gardenTerrace' })}
            to={`/${intl.locale}/garden-terrace`}
            //height="75vh"
          />
        </Box>
        <Box className={classes.img}>
          <SliderImgLifestyle
            src="Component47–1@2x.png"
            alt="Image Lifestyle private-hotel"
            text={intl.formatMessage({ id: 'lifestyle.privateHotel' })}
            to={`/${intl.locale}/private-hotel`}
            //height="75vh"
          />
        </Box>
        <Box className={classes.img}>
          <SliderImgLifestyle
            src="Component48–1@2x.png"
            alt="Image Lifestyle haussmannian-apartment"
            text={intl.formatMessage({ id: 'lifestyle.haussmannianApartment' })}
            to={`/${intl.locale}/haussmannian-apartment`}
            //height="75vh"
          />
        </Box>
      </Carousel>
    </Grid>
  )
}

export default LifeStyleSlider
